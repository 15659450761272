<template>
  <div class="addForm">
        <router-link to="/user/userFeedback">
        <span @click="back">返回</span>
        </router-link>
        <em>/</em>
        <span>问题反馈</span>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="标签">
            <el-input v-model="form.tag" placeholder="举报/网站bug/建议/问题反馈"></el-input>
        </el-form-item>
        <el-form-item label="内容">
            <el-input  type="textarea" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item class="addBtn">
            <el-button type="primary" @click="addFeedBtn">提交反馈</el-button>
          <router-link to="/user/userFeedback">
          <el-button @click="back">取消</el-button>
          </router-link>
        </el-form-item>
        </el-form>

  </div>
</template>

<script>
import { addFeed } from '../../../API/Feedback.js'
export default {
  name: 'addFeedback',
  data () {
    return {
      userName: '123',
      form: {
        isdown:0,
        title: '',
        content: '',
        tag:''
      },
      ruleForm: {
        // ...省略其他
        articleBody: ''
      },
      rules: {
        // ..省略其他
        articleBody: [
          { required: true, message: '请输入文本', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    async  addFeedBtn () {
      if (this.form.title.trim() !== '' || this.form.content.trim() !== '') {
        //   内容不为空时，发起请求
        this.form.isdown=0
        const { data: res } = await addFeed(this.form)
        if (res.status === 0) {
          this.$message({
            showClose: true,
            type: 'success',
            message: res.message
          })
          this.$emit('showFeedInfo', true)
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: res.message
          })
        }
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '标题或内容不能为空'
        })
      }
    },
    back () {
      this.$emit('showFeedInfo', true)
    }
  }

}
</script>

<style lang="less" scoped>
// *{
//     margin: 0;
//     padding: 0;
// }
.addForm{
    background-color: #fff;
    .el-form {
        margin: 10px auto;
        width: 80%;
       /deep/ .el-textarea{
            .el-textarea__inner{
                min-height: 2.1667rem !important;
            }
        }
        /deep/  .ql-editor{
            min-height: 300px;
        }
        .addBtn {

        padding: 20px 0;
        display: flex;
        justify-content: center;
         /deep/ .el-form-item__content {
            margin: 0 !important ;
            button{
                margin: 0 .1667rem;
            }
        }
    }

    }

    span{
            display: inline-block;
            text-align: left;
            height: 48px;
            line-height: 48px;
            font-size: 12px;
            cursor: pointer;
            color: #999aaa;
            padding-left: .1333rem;
            margin: 10px 0;
        }
        span:hover{
              color:#ff3535
            }
        em{
          margin: .0833rem;
        }
}
</style>
