import request from '../until/request.js'

// 发表问题反馈
export const addFeed = data => {
  return request.post('/admin/addFeed', data)
}
// 获取用户发表的问题反馈
export const getUserPubFeedback = data => {
  return request.get('/admin/getUserPubFeedback')
}

// 获取用户发表的问题反馈详情
export const getDetailFeed = data => {
  return request.get('/admin/getDetailFeedback?id=' + data)
}

// 获取问题反馈的评论
export const getComments = data => {
  return request.get('/admin/getComments')
}

// 真删除发表的问题反馈
export const rDelFeed = data => {
  return request.post('/admin/rDelFeed', data)
}

// 假删除发表的问题反馈
export const delFeed = data => {
  return request.post('/admin/delFeed', data)
}

// 下架问题反馈
export const downFeed = data => {
  return request.post('/admin/downFeed', data)
}

// 恢复下架的问题反馈
export const backDownFeed = data => {
  return request.post('/admin/backDownFeed', data)
}

// 删除下架的问题反馈
export const delDownFeed = data => {
  return request.post('/admin/delDownFeed', data)
}
// 恢复删除的问题反馈
export const backDelFeed = data => {
  return request.post('/admin/backDelFeed', data)
}

// 访客获取问答列表
export const getFeed = data => {
  return request.get('/api/getFeed')
}
// 访客获取问答列表详情
export const getDetailFeeds = data => {
  return request.get('/api/getDetailFeed?id=' + data)
}
export const getFeedLikes = data => {
  return request.get('/api/getFeedLikes?id=' + data)
}
// 问题反馈的点赞
export const likeFeed = data => {
  return request.post('/admin/likeFeed',data)
}






